import React from 'react'

function Header(props) {
  console.log("PRINT PROPSSSSSS");
  console.log(props);
  console.log(props.image_logo);
  return (
    <div className="header">
      <a href="/user"><img src={props.image_logo} alt="" /></a>
      <nav className="header__menu">
        <ul className="header__menuItems">
          {/*<a href="/user"><li className="header__menuItem">Home</li></a>
          <a href="https://mysungardensny.com/#what"><li className="header__menuItem">What is Community Solar</li></a>
          <a href="https://mysungardensny.com/#about"><li className="header__menuItem">About Us</li></a>
          <a href="https://SolarFarms.formstack.com/forms/sgny_contactus"><li className="header__menuItem">Contact Us</li></a>
          <li className="header__menuItem header__menuItemSelected">Members</li>*/}
        </ul>
      </nav>
      {
        props.user &&
        <span className="header__name">
          Welcome: {(props.user.first_name).substring(0, 7)}
        </span>
      }
    </div>
  )
}

export default Header