import React from 'react'
import Member from '../templates/Member'
// In Progress
function Profile(props) {
  return (
    <div className="profile">
      <Member {...props}/>
    </div>
  )
}

export default Profile