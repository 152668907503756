import React from 'react'
import Banner from '../shared/Banner';
import Header from '../shared/Header';
function Session(props) {
  return (
    <div className="session">
      <div className="session__top">
        <Header {...props}/>
        <Banner {...props}/>
      </div>
      <div className="session__middle">
        <div className="session__heading">
          {props.heading}
        </div>
        <div className="session__container">
          <div className="session__form">
            {props.form}
          </div>
          {props.formBanner &&
            <div className="session__formBanner">
              {props.formBanner}
            </div>
          }
        </div>
      </div>
      <div className="session__bottom">
        {props.formFooter}
      </div>
      <div className="session__endFooter">
        <div></div>
        <div className="session__bottomBorder"></div>
      </div>
    </div>
  )
}

export default Session