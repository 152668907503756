import { useState, useEffect } from 'react';
import Axios from 'axios';

const useFormForgotPassword = (callback, validate) => {
  const [values, setValues] = useState({
    email: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messages, setMessages] = useState({button: 'This will take a moment...'});
  const [errorChange, setErrorChange] = useState(true);

  useEffect(()=>{
    console.log("IS SUBMITTING");
    console.log("IS SUBMITTING", isSubmitting);
  }, [isSubmitting]);

  const changeURL = (url) =>{
    window.location.replace(url);
  }
  
  const handleChange = e => {
    console.log(e.target);
    console.log("FP HANDLE CHANGE---");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const forgotPassword = (authToken, token) => {
    console.log("FP --- INSIDE CREATE U,SER ---");
    Axios.post('/user/passwords/forgot_password',
    {
      email: values.email,
      authenticity_token: authToken,
      recaptcha_token: token
    }
    ).then((response) => {
      console.log('⛓ ')
      console.log(response);
      console.log("ERRORS");
      console.log(errors);
      console.log(isSubmitting);
      console.log(isSubmitting);
      setErrors({});
      setMessages({button: 'success, redirecting...'});
      changeURL('/user/login');
    }, (error) => {
      console.log('🐇 ')
      console.log(error);
      console.log(error.response);
      setErrors(error.response.data.errors);
      setIsSubmitting(false);
    });
  }

  const handleSubmit = async (e, authTok, tok='') => {
    console.log("FP IN SUBMIT--- 🧷 ");
    console.log(tok);
    let validation_errors = await validate(values);
    console.log("BEFORE SET ERRORS");
    console.log(errors);
    console.log("VALID ERRORS");
    console.log(validation_errors);
    let await_errors = await setErrors(validation_errors);
    console.log("AFTER SET ERRORS");
    console.log(errors);
    console.log("AWAIT ERRORs");
    console.log(await_errors);
    if(Object.keys(validation_errors).length === 0){
      forgotPassword(authTok, tok);
    }else{
      setIsSubmitting(false);
    }
  };

  useEffect(
    () => {
      if (Object.keys(errors).length === 0 && !isSubmitting && !errorChange) {
        console.log("IN USE EFFECTTTT");
        callback();
        console.log("AFTER USEEFFECTTTT");
      }
      setErrorChange(false);
    },
    
    [errors]
  );

  return { handleChange, handleSubmit, values, errors, messages, setIsSubmitting, isSubmitting };
};

export default useFormForgotPassword;
