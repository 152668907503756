import React from 'react'

const FormBanner = (props) => {
  return (
    <div className="formBanner">
      <img className="formBanner__img" src={props.app_images.member_login_banner} alt="" />
    </div>
  )
}

export default FormBanner
