
export default function validateLoginInfo(values) {
  console.log("VALIDATE --> 🥪 ");
  let errors = {};

  if (!values.email) {
    errors.error = 'Email required';
    return errors;
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.error = 'Email address is invalid';
    return errors;
  }

  if (!values.password) {
    errors.error = 'Password is required';
    return errors;
  }

  return errors;
}