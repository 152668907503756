import React from 'react'
// In Progress
function SideMenu(props) {
  console.log("SideMenu PRINT PROPSSSSSS");
  console.log(props);
  const active_path = (
    (window.location.pathname.indexOf('/user/edit/payment') == 0) ? 'payment' :
      (window.location.pathname.indexOf('/user/details') == 0) ? 'account' : 'profile')

  const menu_items = {
    'profile': { 'name': 'MY PROFILE', 'url': '/user' },
    'account': { 'name': 'ACCOUNT DETAILS', 'url': props.customer ? `/user/details/${props.customer.uuid}` : '/user/details' },
    'payment': { 'name': 'PAYMENT METHOD', 'url': "#" },
    'logout': { 'name': props.admin ? 'RETURN' : 'LOGOUT', 'url': '/user/logout' }
  }

  return (
    <div className="sideMenu side-menu bg-menu-primary">
      <nav className="sideMenuItems">
        <li className={(active_path == 'profile') ? 'nav-item active' : 'nav-item'}>
          <a href="/user" >MY PROFILE</a>
        </li>

        <li className={(active_path == 'account') ? 'nav-item active' : 'nav-item'}>
          <a href={props.customer ? `/user/details/${props.customer.uuid}` : '/user/details'} >ACCOUNT DETAILS</a>
        </li>

        <li className={(active_path == 'payment') ? 'nav-item active' : 'nav-item'}>
          <a href={`/user/edit/payment/${props.customer.uuid}`} >PAYMENT METHOD</a>
        </li>

        <li className="nav-item">
          <a href="/user/logout" >{props.admin ? 'RETURN' : 'LOGOUT'}</a>
        </li>
      </nav>
      <span className="nav-footer">
        <b>{props.env_vars.ui_company_title}</b>
        <span className="nav-footer-small">
          <br/>{props.env_vars.ui_menu_address_1}
          <br/>{props.env_vars.ui_menu_address_2}
          <br/>{props.env_vars.ui_menu_phone}
          <br/>{props.env_vars.ui_menu_email}
        </span>
      </span>
    </div>
  )
}

export default SideMenu