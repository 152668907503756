import React, { useState, useEffect } from 'react';
import FormForgotPassword from './forms/FormForgotPassword';
import SuccessForgotPassword from './forms/SuccessForgotPassword'
import Session from './templates/Session';
import FormBanner from './shared/FormBanner';
import parse from "html-react-parser";

const HeadingMessage = (props) => {
  return (
    <>
      {parse(props.app_content.forgot_heading)}
    </>
  )
}

const ForgotPassword = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    console.log("IS SUBMIT UE");
    console.log(isSubmitted);

  }, [isSubmitted]);

  function submitForm() {
    console.log("IN FP SUBMIT FORM");
    setIsSubmitted(true);
    setEmail(email);
  }
  const forgotPasswordForm = () => {
    return (
      <>
        <Session
          form={<FormForgotPassword submitForm={() => { console.log("IN FP SUBMIT FORM"); setIsSubmitted(true); }} {...props} />}
          formBanner={<a href={props.app_images.member_login_banner_url}><FormBanner {...props} /></a>}
          heading={<HeadingMessage {...props} />}
          {...props}
        />
      </>
    )
  }

  const forgotPasswordSuccess = () => {
    return (
      <>
        <Session form={<SuccessForgotPassword {...props} />}
          email={email}
          formBanner={<a href={props.app_images.member_login_banner_url}><FormBanner {...props} /></a>}
          heading={<HeadingMessage {...props} />} {...props} />
      </>
    )
  }
  console.log("IS SUBMITTED", isSubmitted);

  return (
    <div className="signUp">
      {isSubmitted ? forgotPasswordSuccess() : forgotPasswordForm()}
    </div>
  )
};

export default ForgotPassword