import { useState, useEffect } from 'react';
import Axios from 'axios';

const useFormSignUp = (callback, validate) => {
  const [values, setValues] = useState({
    zipCode: '',
    email: '',
    password: '',
    rePassword: ''
  });
  // console.log("USE FORM SIGNUP");
  // console.log(callback);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messages, setMessages] = useState({button: 'This will take a moment...', status: ''});
  const [errorChange, setErrorChange] = useState(true);

  const changeURL = (url) =>{
    window.location.href = url;
  }
  
  const handleChange = e => {
    // console.log(e.target);
    // console.log("HANDLE CHANGE---");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const createUser = (validation_errors, authToken, token) => {
    // console.log("---CREATE USER ---");
    // console.log(validation_errors);
    if(Object.keys(validation_errors).length === 0){
      // console.log("--- INSIDE CREATE USER ---");
      Axios.post('/user',
      {
        email: values.email,
        password: values.password,
        re_password: values.rePassword,
        zip_code: values.zipCode,
        authenticity_token: authToken,
        recaptcha_token: token
      }
      ).then((response) => {
        // console.log('⛓ ')
        // console.log(response);
        setErrors({});
        // console.log("ERRORS");
        // console.log(errors);
        // console.log(isSubmitting);
        // console.log(isSubmitting);
        // console.log("| IS SUBMITTED ----->");
        // console.log(response);
        setMessages({button: 'success, redirecting...', status: 'success'});
        callback();
      }, (error) => {
        // console.log('🐇 ')
        // console.log(error);
        // console.log(error.response);
        setErrors(error.response.data.errors);
        setMessages({button: 'error, redirecting...', status: ''});
        setIsSubmitting(false);
      });
    }else{
      setIsSubmitting(false);
    }
  }

  const handleSubmit = async (e, authTok, tok='' ) => {
    let validation_errors = await validate(values);
    let await_errors = await setErrors(validation_errors);
    createUser(validation_errors, authTok, tok);
    setMessages({button: 'processing...', status: ''});
  };

  useEffect(
    () => {  
      if (Object.keys(errors).length === 0 && !isSubmitting && !errorChange) {
        callback();
      }
      setErrorChange(false);
    },
    [errors]
  );

  return { handleChange, handleSubmit, values, errors, messages, setIsSubmitting, isSubmitting };
};

export default useFormSignUp;
