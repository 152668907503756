import React from 'react'

function Banner(props) {
  return (
    <div className="banner">
      <img src={props.app_images.main_banner} alt=""/>
    </div>
  )
}

export default Banner
