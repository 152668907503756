import React from 'react'
import Header from '../shared/Header';
import SideMenu from '../shared/SideMenu';
// In  Progress
function Member(props) {
  return (
    <div className="member">
      <div className="member__top">
        <Header {...props}/>
      </div>
      <div className="member__wrapper">
        <div className="member__menu">
          <SideMenu {...props}/>
        </div>
        <div className="member__container">
          <div className="member__content">
            CONTENT
          </div>
          <div className="member__footer">
            FOOTER
          </div>
        </div>
      </div>
    </div>
  )
}

export default Member