import ReactOnRails from 'react-on-rails';
import MainPage from '../bundles/Application/components/member/MainPage';
import SignUp from '../bundles/Application/components/member/SignUp';
import ForgotPassword from '../bundles/Application/components/member/ForgotPassword';
import Header from '../bundles/Application/components/member/shared/Header';
import SideMenu from '../bundles/Application/components/member/shared/SideMenu';
import Profile from '../bundles/Application/components/member/views/Profile';


// This is how react_on_rails can see the Components in the browser.
ReactOnRails.register({
  Header,
  MainPage,
  SignUp,
  ForgotPassword,
  SideMenu,
  Profile,
});
